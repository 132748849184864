import { Modal } from '@style-space/components/src/Modal'
import { pxToRem } from '@style-space/components/src/lib/utils'
import styled, { css } from 'styled-components'

export const SModal = styled(Modal)(
  ({ theme }) => css`
    .modal-inner-wrap {
      padding: 0;
      max-width: ${theme.maxScreen};
      background-color: transparent;

      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        max-width: ${pxToRem(960)};
        min-height: unset;
        margin: auto auto;
      }
    }

    .modal-x {
      width: ${pxToRem(32)};
      height: ${pxToRem(32)};
      padding: 0;
      top: ${pxToRem(8)};
      right: ${pxToRem(8)};
      border-radius: 50%;
      background-color: ${theme.color.white};

      svg {
        width: ${pxToRem(12)};
        height: ${pxToRem(12)};
      }
    }
  `,
)
