import { useEffect } from 'react'

import { useMixpanel } from './useMixpanel'

export const useScreenView = (
  pageName: string,
  params: { [key: string]: string } = {},
) => {
  const { track } = useMixpanel()
  useEffect(() => {
    if (!pageName) {
      return
    }
    setTimeout(() => {
      track('View Screen', { 'Screen Name': pageName || '', ...params })
    }, 300)
  }, [pageName])
}
