import { useState, useCallback, useEffect, useRef } from 'react'

import { fetch, GraphqlResponse } from './fetch'
import { compareRecords, GRAPHQL_URL } from '../utils'
import { handleError, UNEXPECTED_ERROR } from './error'
import { getToken } from './token'
import { getAuthHeaders } from './utils'
import { GraphqlResponseError } from '../error/GraphQlError'

export type AnyVariables = {
  [key: string]: string | number | boolean | string[]
}

export type FetchParams<V = AnyVariables> = {
  url?: string
  query?: string
  headers?: { [key: string]: string }
  variables?: V
}

export type HookResponse<T, V> = {
  refetch: (params?: FetchParams<V>) => Promise<GraphqlResponse<T> | null>
  loading: boolean
  data: T | null
  errors: GraphqlResponseError[] | null
  error: string | null
}

const RETRY_LIMIT = 5

/**
 * React hook wrapper around fetch
 * @param query Graphql query
 * @param options Additional options
 * @param options.run Run automatically
 * @param options.url Endpoint url, default ENV used if not specified
 * @param options.headers Request headers
 * @param options.variables Graphql variables
 */
export const useFetch = <T = any, V extends AnyVariables = AnyVariables>(
  query: string,
  options: FetchParams<V> & {
    run?: boolean
  } = {},
): HookResponse<T, V> => {
  const {
    run = true,
    url = GRAPHQL_URL,
    headers = {},
    variables = {},
  } = options
  const abortControllerRef = useRef<AbortController>()
  const retryCounter = useRef(0)
  const prevVariables = useRef(variables)
  const [loading, setLoading] = useState(run || false)
  const [data, setData] = useState<T | null>(null)
  const [errors, setErrors] = useState<GraphqlResponseError[] | null>(null)

  useEffect(() => {
    if (typeof AbortController === 'undefined') {
      return
    }
    abortControllerRef.current = new AbortController()
    return () => {
      abortControllerRef.current!.abort()
    }
  }, [])

  const refetch = useCallback(
    async (params: FetchParams<V> = {}): Promise<GraphqlResponse<T> | null> => {
      const urlToFetch = params.url || url
      if (!urlToFetch) {
        throw new Error('Url to fetch is not defined')
      }
      setLoading(true)
      setErrors(null)
      try {
        const token = getToken()
        const data = await fetch(
          urlToFetch,
          params.query || query,
          {
            ...(token ? getAuthHeaders(token) : {}),
            ...headers,
            ...(params.headers || {}),
          },
          { ...variables, ...(params.variables || {}) },
          abortControllerRef.current?.signal,
        )
        if (data.errors && data.errors.length > 0) {
          const errorExpected = await handleError(data.errors)
          if (errorExpected && retryCounter.current < RETRY_LIMIT) {
            retryCounter.current += 1
            return refetch(params)
          }
          setErrors(data.errors)
        }
        retryCounter.current = 0
        setData(data.data)
        return data
      } catch (e: any) {
        retryCounter.current = 0
        setErrors([{ message: UNEXPECTED_ERROR }])
        return null
      } finally {
        setLoading(false)
      }
    },
    [query, url, headers, variables],
  )

  useEffect(() => {
    if (!run) {
      return
    }
    if (compareRecords(prevVariables.current, variables)) {
      return
    }
    prevVariables.current = variables
    refetch()
  }, [variables])

  useEffect(() => {
    if (!run) {
      return
    }
    refetch()
  }, [run])

  return {
    refetch,
    loading,
    data,
    errors,
    error: errors?.length ? errors[0].message : null,
  }
}
