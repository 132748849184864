import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

const Wrap = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

const Wrap2 = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    padding-top: var(--ratio);

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      padding-top: var(--screenRatio);
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding-top: var(--tabletRatio);
    }
  `,
)

type Props = PropsWithChildren<{
  ratio: number
  screenRatio?: number
  tabletRatio?: number
  className?: string
}>

export const RatioWrap: React.FC<Props> = ({
  ratio,
  screenRatio = ratio,
  tabletRatio = screenRatio,
  children,
  className,
}) => {
  const paddingStyle = {
    '--ratio': `${ratio}%`,
    '--screenRatio': `${screenRatio}%`,
    '--tabletRatio': `${tabletRatio}%`,
  } as any
  return (
    <Wrap data-testid="ratioWrap" className={`ratio-wrap ${className}`}>
      <Wrap2 data-testid="ratioWrapInner" style={paddingStyle}>
        {children}
      </Wrap2>
    </Wrap>
  )
}
RatioWrap.displayName = 'RatioWrap'
