import queryString from 'query-string'
import {
  ADMIN_ROUTES,
  ROUTES as MASTER_ROUTES,
} from '@style-space/components/src/lib/utils/routes'

import { DateTime } from '../../@types/types'
import { CANONICAL } from './constants'
import { formatDate, getTime, toDurationFormat } from '../lib/time'

export * from '@style-space/components/src/lib/utils/routes'

export const STYLIST_AWARDS_ID = 'stylist-awards'

export const EBOOK_ROUTE = '/5_Steps_to_finding_your_dream_style.pdf'

export const ROUTES = {
  ...MASTER_ROUTES,
  notFound: '/404',
  welcome: '/welcome',
  addNotes: '/add-notes',
  editNotes: '/edit-notes',
  notes: '/notes',
  account: '/account',
  accountPayments: '/account/payments',
  accountSettings: '/account/settings',
  accountIntegrations: '/account/integrations',
  accountUpdate: '/account/update',
  settings: '/settings',
  portfolio: '/portfolio',
  notifications: '/notifications',
  payments: 'account/payments',
  help: '/help',
  booking: '/booking',
  bookings: '/bookings',
  confirmBooking: '/confirm-booking',
  profile: '/profile',
  profilePublic: '/profile/public',
  sessions: '/sessions',
  session: '/session',
  editSession: '/stylist/my/edit',
  addSession: '/stylist/my/add',
  availabilities: '/stylist/availabilities',
  sessionTimes: '/sessions/times',
  userProfile: '/user-profile',
  stylistsSession: '/stylist/my',
  stylistEdit: '/stylist-profile',
  experiences: '/experiences',
  forgot: '/forgot',
  checkout: '/checkout',
  reserveSlot: '/reserve-slot',
  howItWorks: '/how-it-works',
  becomeStylist: '/join_us',
  newStylist: '/join_us/application',
  newStylistStatus: '/join_us/status',
  newStylistProfile: '/join_us/profile',
  newStylistProfileComplete: '/join_us/profile-complete',
  payouts: '/account/payouts',
  transactions: '/account/transactions',
  addReview: '/review/add',
  sessionMatching: '/session-matching',
  profileUpdate: '/profile-update',
}

export const GIFT_UP_ROUTE = process.env.NEXT_PUBLIC_GIFT_UP_ROUTE

export const getSearchParams = () => {
  if (typeof window === 'undefined') {
    return {}
  }
  return queryString.parse(location.search)
}

export const getWelcomeRoute = () => {
  const { pathname, search } = window.location
  return `${ROUTES.welcome}?path=${encodeURIComponent(`${pathname}${search}`)}`
}

export const getSessionMatchingRoute = (step: string) => {
  return `${ROUTES.sessionMatching}?step=${step}`
}

export const getProfileUpdateRoute = (step?: string, path?: string) => {
  const { pathname, search } = window.location
  return `${ROUTES.profileUpdate}?${queryString.stringify({
    step,
    path: path ? path : `${pathname}${search}`,
  })}`
}

export const getAddReviewRoute = (id: string) => `${ROUTES.addReview}?id=${id}`
export const getUserProfileRoute = (bookingId: string) =>
  `${ROUTES.userProfile}?id=${bookingId}`

export const getBookingRoute = (id: string, params: any = {}) =>
  `${ROUTES.booking}?${queryString.stringify({ id, ...params })}`

export const getConfirmBookingRoute = (id: string) =>
  `${ROUTES.confirmBooking}?id=${id}`

export const getReserveRoute = (id: string, dateTime: string) =>
  `${ROUTES.reserveSlot}?id=${id}&when=${dateTime}`

export const getCheckoutRoute = (id: string) => `${ROUTES.checkout}?id=${id}`

export const getAddNotesRoute = (id: string) => `${ROUTES.addNotes}?id=${id}`
export const getNotesRoute = (id: string) => `${ROUTES.notes}?id=${id}`

export const getApplicationRoute = (id: string) =>
  `${ADMIN_ROUTES.application}?id=${id}`

export const getSessionRoute = (id: string) => {
  return `${ROUTES.sessions}/${id}`
}

export const getSessionSlugRoute = (slug: string) => {
  return `${ROUTES.session}/${slug}`
}
export const getEditSessionRoute = (id: string) =>
  `${ROUTES.editSession}?id=${id}`

export const getAdminApplicationsRoute = (tab?: string) => {
  if (!tab) {
    return `${ADMIN_ROUTES.applications}/pending`
  }
  return `${ADMIN_ROUTES.applications}/${tab}`
}

export const getAccountRoute = (tab?: string) => {
  if (!tab) {
    return `${ROUTES.account}/personal-information`
  }
  return `${ROUTES.account}/${tab}`
}

export const getAccountSettingsRoute = () => {
  return getAccountRoute('settings')
}

export const getAccountIntegrationsRoute = () => {
  return getAccountRoute('integrations')
}

export const getProfileEditRoute = (tab?: string) => {
  if (!tab) {
    return `${ROUTES.profile}/about`
  }
  return `${ROUTES.profile}/${tab}`
}

export const getStylistEditRoute = (tab?: string) => {
  if (!tab) {
    return `${ROUTES.stylistEdit}/about`
  }
  return `${ROUTES.stylistEdit}/${tab}`
}

export const getSessionTimesRoute = (
  id: string,
  start: DateTime,
  end: DateTime,
) => `${ROUTES.sessionTimes}/${id}?start=${start}&end=${end}`

export const getCalendarHTMLDescription = (
  id: string,
  url: string,
  description: string,
) => {
  const bookingUrl = `${CANONICAL}${getBookingRoute(id)}`
  return `
  <b>Online session:</b><br /><a href="${url}">${url}</a><br />
<br />
<b>Session link:</b><br /><a href="${bookingUrl}">${bookingUrl}</a><br />
  <br />
<b>Please join your style session link 5 minutes before the start time on your mobile device or computer. Test out your zoom connection <a href={EXTERNAL_ROUTES.zoomTestCall}>here</a></b><br/>
  <br />
<b>Session description:</b><br/>${description}`
}

export const getGoogleCalendarLink = (
  title: string,
  description: string,
  location: string,
  time: number,
  duration: number,
) => {
  const zoonedTime = getTime(time)
  const timeformat = 'YYYYMMDDTHHmmss[Z]'
  const start = formatDate(zoonedTime, timeformat, 'UTC')
  const end = formatDate(
    zoonedTime + toDurationFormat(duration, 'minute', 'millisecond'),
    timeformat,
    'UTC',
  )
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}%2F${end}&details=${encodeURIComponent(
    description,
  )}&location=${location}&text=${encodeURIComponent(title)}`
}
