import React from 'react'
import dynamic from 'next/dynamic'

import { RatioWrap } from '../../common/RatioWrap'
import { SModal } from './VideoModal.style'

type VideoSource = { src: string; type: string }

const Video: any = dynamic((): any =>
  import('./Video').then((mod) => mod.Video),
)

type Props = {
  modalName: string
  isOpen: boolean
  onClose(): void
  srcs: VideoSource[]
}

export const VideoModal: React.FC<Props> = ({
  modalName,
  isOpen,
  onClose,
  srcs,
}) => {
  return (
    <SModal modalName={modalName} isOpen={isOpen} onClose={onClose}>
      <RatioWrap ratio={56}>
        <Video options={{ sources: srcs }}>
          {srcs.map(({ src, type }) => (
            <source
              data-testid={`video-modal-source`}
              key={src}
              src={src}
              type={type}
            />
          ))}
          Sorry, your browser doesn't support embedded videos.
        </Video>
      </RatioWrap>
    </SModal>
  )
}
VideoModal.displayName = 'VideoModal'
