import React, { useCallback, useEffect, useRef, useState } from 'react'

import { compareRecords } from '../utils'
import { useFetch, AnyVariables, FetchParams } from './useFetch'

export const usePaginatedQuery = <
  T = any,
  Y = any,
  V extends AnyVariables = AnyVariables,
>(
  query: string,
  options: FetchParams<V> & {
    amount?: number
    run?: boolean
  },
  getter: (data: any) => any,
) => {
  const [pagedData, setPagedData] = React.useState<T[]>([])
  const { variables = {}, amount = 10, ...restOptions } = options
  const [offset, setOffset] = useState(0)
  const prevVariables = useRef(variables)
  const isNewVariables = !compareRecords(prevVariables.current, variables)
  const { data, ...rest } = useFetch<Y>(query, {
    variables: {
      offset: isNewVariables ? 0 : offset,
      amount,
      ...variables,
    },
    ...restOptions,
  })

  const hasMore = getter(data)?.paginationInfo?.hasMore || false
  const total = getter(data)?.paginationInfo?.totalResults || 0

  useEffect(() => {
    if (isNewVariables) {
      prevVariables.current = variables
      if (offset !== 0) {
        setOffset(0)
      }
    }
  }, [isNewVariables])

  const nextPage = useCallback(() => {
    if (!hasMore) {
      return
    }
    setOffset((prev) => prev + amount)
  }, [amount, hasMore, variables, setOffset])

  const resetPage = useCallback(() => {
    setOffset(0)
  }, [setOffset])

  useEffect(() => {
    const currentResults: T[] = (getter(data)?.results as T[]) || []
    if (offset === 0) {
      setPagedData(currentResults)
      return
    }
    setPagedData((prev) => [...prev, ...currentResults])
  }, [data])

  return {
    currentData: data,
    data: pagedData,
    nextPage,
    resetPage,
    hasMore,
    total,
    ...rest,
  }
}
