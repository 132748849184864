import { DefaultProps } from '@style-space/components/src/types'
import React from 'react'
import styled, { css } from 'styled-components'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.grey['900']};
    }
  `,
)

export const Play: React.FC<DefaultProps> = ({ className = '' }) => {
  return (
    <Svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 28.7999C23.0692 28.7999 28.7999 23.0692 28.7999 16C28.7999 8.93071 23.0692 3.19995 16 3.19995C8.93071 3.19995 3.19995 8.93071 3.19995 16C3.19995 23.0692 8.93071 28.7999 16 28.7999ZM15.2875 11.4687C14.7965 11.1414 14.1652 11.1108 13.645 11.3893C13.1247 11.6677 12.8 12.2099 12.8 12.8V19.2C12.8 19.79 13.1247 20.3322 13.645 20.6106C14.1652 20.8891 14.7965 20.8585 15.2875 20.5312L20.0875 17.3312C20.5326 17.0345 20.7999 16.5349 20.7999 16C20.7999 15.465 20.5326 14.9654 20.0875 14.6687L15.2875 11.4687Z"
      />
    </Svg>
  )
}
Play.displayName = 'Play'
