import { ENV } from '@style-space/components/src/lib/utils'
import React from 'react'

import { VideoModal } from '../VideoModal'

const MOCK_SRCS = [
  {
    src: `https://stylespace-${ENV.toLocaleLowerCase()}.s3.eu-central-1.amazonaws.com/homevideo.mp4`,
    type: 'video/mp4',
  },
  {
    src: `https://stylespace-${ENV.toLocaleLowerCase()}.s3.eu-central-1.amazonaws.com/home-video.webm`,
    type: 'video/webm',
  },
]

type Props = {
  isOpen: boolean
  onClose(): void
}

export const HomeVideoModal: React.FC<Props> = (props) => {
  return <VideoModal modalName="HomeVideoModal" {...props} srcs={MOCK_SRCS} />
}
