import styled, { css } from 'styled-components'

import { pxToRem } from '../../utils/utils'
import { TOAST_CLASS } from '../../context/ToastContext/ToastContext'

export const Page = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    background-color: ${theme.color.white};
    max-width: ${theme.breakpoint.laptop};
    margin: 5.5rem auto;
    overflow-x: hidden;
    transition: 150ms margin-top;

    .${TOAST_CLASS} & {
      margin-top: ${pxToRem(136)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin-top: ${pxToRem(75)};

      .${TOAST_CLASS} & {
        margin-top: ${pxToRem(121)};
      }
    }
  `,
)

export const Page2 = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    min-height: 80vh;
    background-color: ${theme.color.white};
    max-width: ${theme.breakpoint.laptop};
    margin: 0 auto;
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
    transition: 150ms padding-top;

    .${TOAST_CLASS} & {
      padding-top: ${pxToRem(134)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding-top: ${pxToRem(75)};

      .${TOAST_CLASS} & {
        padding-top: ${pxToRem(121)};
      }
    }
  `,
)
