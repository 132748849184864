import React, { useCallback, useState } from 'react'
import { DefaultProps } from '@style-space/components/src/types'
import { useTheme } from 'styled-components'

import {
  InnerWrap,
  SPlay,
  Button,
  Text,
  Section,
  PlayWrap,
  ImageWrap,
  SImage,
} from './VideoSection.style'
import Background from '../../../../public/images/video-section.jpg'
import AsideBackground from '../../../../public/images/video-section-aside.jpg'
import MobileBackground from '../../../../public/images/mobile-video-section.jpg'
import { HomeVideoModal } from '../VideoModal/HomeVideoModal'

type Props = DefaultProps & {
  videoSectionType?: 'standalone' | 'partOf'
}

export const VideoSection: React.FC<Props> = ({
  className = '',
  videoSectionType = 'standalone',
}) => {
  const [open, setOpen] = useState(false)
  const { maxScreen } = useTheme()
  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])
  const onClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  return (
    <Section
      data-testid="video-section"
      className={`video-section ${className} ${videoSectionType}`}
    >
      <Button onClick={onOpen} data-testid="video-section-button">
        <ImageWrap className={`aside ${videoSectionType}`}>
          <SImage
            alt=""
            placeholder="blur"
            src={AsideBackground}
            sizes={maxScreen}
          />
        </ImageWrap>
        <ImageWrap className={`fullscreen ${videoSectionType}`}>
          <SImage
            alt=""
            placeholder="blur"
            src={Background}
            sizes={maxScreen}
          />
        </ImageWrap>
        <ImageWrap className="mobile">
          <SImage
            alt=""
            placeholder="blur"
            sizes={'100vw'}
            src={MobileBackground}
          />
        </ImageWrap>
        <InnerWrap>
          <PlayWrap className="play-wrap">
            <SPlay />
          </PlayWrap>
          <Text>See how it works</Text>
        </InnerWrap>
      </Button>
      <HomeVideoModal isOpen={open} onClose={onClose} />
    </Section>
  )
}
VideoSection.displayName = 'VideoSection'
