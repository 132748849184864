import styled, { css } from 'styled-components'
import { pxToRem } from '@style-space/components/src/lib/utils'
import NextImage from 'next/image'

import { Play } from '../icons/Play'

export const Section = styled.section(
  ({ theme }) => css`
    margin: ${pxToRem(0, 'auto', 96)};
    padding: ${pxToRem(0, 20)};
    width: 100%;
    max-width: calc(${theme.maxScreen} + ${pxToRem(40)});
    &.partOf {
      padding: 0;
    }
  `,
)

export const Button = styled.button(
  ({ theme }) => css`
    width: 100%;
    position: relative;
    border: none;
    border-radius: ${theme.radius.small};
    overflow: hidden;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    background-color: transparent;

    :focus {
      outline: none;
    }

    :hover,
    :focus-visible {
      .play-wrap {
        border: ${pxToRem(2)} solid ${theme.color.white};
      }
    }
  `,
)

export const SImage = styled(NextImage)`
  width: 100%;
  height: auto;
  object-fit: cover;
`

export const InnerWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PlayWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: ${pxToRem(64)};
    height: ${pxToRem(64)};
    border: ${pxToRem(2)} solid transparent;
    border-radius: 50%;
    margin-bottom: ${pxToRem(16)};

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      width: ${pxToRem(48)};
      height: ${pxToRem(48)};
      margin-bottom: ${pxToRem(8)};
    }
  `,
)

export const SPlay = styled(Play)(
  ({ theme }) => css`
    position: absolute;
    top: ${pxToRem(-2)};
    left: ${pxToRem(-2)};
    width: ${pxToRem(64)};
    height: ${pxToRem(64)};
    path {
      fill: ${theme.color.white};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      width: ${pxToRem(48)};
      height: ${pxToRem(48)};
    }
  `,
)

export const Text = styled.div(
  ({ theme }) => css`
    font-size: ${pxToRem(24)};
    font-family: ${theme.font.poppins};
    color: ${theme.color.white};

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      font-size: ${pxToRem(18)};
    }
  `,
)

export const ImageWrap = styled.div(
  ({ theme }) => css`
    &.fullscreen {
      display: block;
      &.partOf {
        display: none;
        @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
          display: block;
        }
        @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
          display: none;
        }
      }
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        display: none;
      }
    }

    &.aside {
      display: none;
      &.partOf {
        display: block;
        @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
          display: none;
        }
      }
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        display: block;
      }
    }
  `,
)
