import currency from 'currency.js'

import { Currency } from './types'

const getCentsString = (cents: number) => {
  if (cents < 10) {
    return `0${cents}`
  }
  return `${cents}`
}

const PATTERN = '!#'

const format = (
  currency: currency | undefined,
  { symbol = '', decimal, pattern = PATTERN }: currency.Options = {},
) => {
  if (!currency) {
    return ''
  }
  const centAmount = currency.cents()
  const cents = centAmount ? `${decimal}${getCentsString(centAmount)}` : ''
  return pattern
    .replace('!', symbol)
    .replace('#', `${currency.dollars()}${cents}`)
}

const CURRENCY_CONFIG: { [key in Currency]: currency.Options } = {
  USD: {
    symbol: '$',
    precision: 2,
    format,
  },
  EUR: {
    symbol: '€',
    precision: 2,
    format,
  },
  GBP: {
    symbol: '£',
    precision: 2,
    format,
  },
}

const getCurrency = (
  curr: Currency,
  amount: number,
  pattern: string = PATTERN,
) => {
  return currency(amount, { ...CURRENCY_CONFIG[curr], pattern })
}

/**
 * Format price amount to currency format
 * <br />
 *  23.05 USD => $23.05<br />
 *  23.05 EUR => €23.05<br />
 *  <br />
 *  Pattern:<br />
 *  !  => currency symbol<br />
 *  \# => amount<br />
 *  !\# => $20<br />
 *  (!)\# => ($)20<br />
 *
 * @param curr Currency to format to
 * @param amount Price to format
 * @param pattern Pattern of the result
 */
export const formatPrice = (
  curr: Currency,
  amount: number,
  pattern?: string,
) => {
  return getCurrency(curr, amount, pattern).format()
}

/**
 * Format price amount to currency format and split it into parts
 * <br />
 *  23.05 USD => ["$", "23.05"]<br />
 *  23.05 EUR => ["€", "23.05"]<br />
 *
 * @param curr Currency to format to
 * @param amount Price to format
 */
export const formatPriceParts = (
  curr: Currency,
  amount: number,
): [string, string] => {
  return [
    CURRENCY_CONFIG[curr].symbol || '',
    getCurrency(curr, amount, '#').format(),
  ]
}
